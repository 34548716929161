import repository from './repository'

const resource = 'rack-eggs'

export default {
  index (params) {
    return repository.get(resource, { params })
  },
  store (data) {
    return repository.post(resource, data)
  },
  storeMany (data) {
    return repository.post(`${resource}/store-many`, data)
  },
  incubatorRackIds (params) {
    return repository.get(`${resource}/incubator-rack-ids`, { params })
  },
  transfer (id, data) {
    return repository.put(`${resource}/${id}/transfer`, data)
  },
  update (id, data) {
    return repository.put(`${resource}/${id}`, data)
  },
  empty (id) {
    return repository.post(`${resource}/${id}/empty`)
  },
}
