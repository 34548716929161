<template lang="pug">
  div
    v-row
      v-col(cols="12")
        v-autocomplete(
          v-if="!editMode"
          label="Rack Number"
          v-model="incubatorRackId"
          :items="incubatorRackList"
          item-value="id"
          dense
          item-text="code"
          :loading="incubatorRackGetting"
          hide-details="auto"
          :error-messages="rackEggPostErrors.incubator_rack_id"
        )
          template(v-slot:item="{ item }")
            span(
              :class="rackNumberClass(item)"
            ) {{ item.code }}
        v-text-field(
          v-else
          label="Rack Number"
          disabled
          :value="defaultData.rack_code.code"
          hide-details
        )
      v-col(cols="12")
        v-text-field(
          label="Qty"
          dense
          type="number"
          v-model="quantity"
          hide-details="auto"
          :error-messages="rackEggPostErrors.quantity"
        )
      v-col(cols="12")
        v-text-field(
          label="Date"
          dense
          type="date"
          v-model="date"
          hide-details="auto"
          :error-messages="rackEggPostErrors.date"
        )
      v-col(cols="12" v-if="!editMode")
        v-btn(
          color="green"
          text
          @click="store"
          :loading="rackEggPosting"
        )
          span Save
      v-col(cols="12" v-else)
        v-row
          v-col(cols="6")
            v-btn(
              color="yellow darken-3"
              dark
              dense
              block
              @click="update"
              :loading="rackEggPosting"
            ) Update
          v-col(cols="6")
            v-btn(
              color="red"
              dark
              dense
              block
              @click="editMode = false"
            ) Cancel
</template>
<script>
// import incubatorRepository from '@/repositories/incubator.repository'
import rackEggRepository from '@/repositories/rack-egg.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

// const [incubatorRackVars, incubatorRackVarNames] = requestVars({ identifier: 'incubator-rack' })
const [rackEggVars, rackEggVarNames] = requestVars({ identifier: 'rack-egg', request: 'post', hasData: false })

// const incubatorRackHandler = new VueRequestHandler(null, incubatorRackVarNames)
const rackEggHandler = new VueRequestHandler(null, rackEggVarNames)

const inputVars = () => ({
  id: null,
  incubatorRackId: null,
  quantity: null,
  date: Window.getCurrentDate(),
})

export default {
  name: 'CreateRackEgg',
  props: {
    defaultData: Object,
    incubatorRackListItems: Array,
  },
  data () {
    return {
      incubatorRackListSearch: null,
      ...inputVars(),
      ...rackEggVars,
      incubatorRackList: [],
      // ...incubatorRackVars,
    }
  },
  computed: {
    editMode: {
      get () {
        return !this.$objectEmpty(this.defaultData)
      },
      set (val) {
        if (val) return
        this.$emit('update:default-data', {})
      },
    },
  },
  created () {
    // this.getRacks()
    this.initWebsocket()
  },
  watch: {
    // incubatorRackListSearch () {
    //   this.getRacks()
    // },
    incubatorRackListItems (list) {
      this.incubatorRackList = list
    },
    defaultData () {
      const input = inputVars()
      this.$populateInputData({ input })
    },
  },
  methods: {
    initWebsocket () {
      const { echo } = this.$store.state.websocket
      const storeEvent = ({ model, data }) => {
        if (model === 'RackEgg') {
          const index = this.incubatorRackList.findIndex(item => item.id === data.incubator_rack_id)
          if (index === -1) return
          const temp = this.incubatorRackList.splice(index, 1)[0]
          temp.loaded_eggs = data
          this.incubatorRackList.shift(temp)
        }
      }
      echo.private('database.event')
        .listen('DBStoreEvent', storeEvent)
    },
    // getRacks () {
    //   const handler = incubatorRackHandler
    //   const repository = incubatorRepository.racks
    //   const params = { search: this.incubatorRackListSearch }
    //   handler.setVue(this)
    //   handler.execute(repository, [params])
    // },
    async update () {
      const confirm = await this.$confirm('are you sure you want to update this quantity?')
      if (!confirm) return
      const handler = rackEggHandler
      const repository = rackEggRepository.update
      const { id, ...data } = this.$getInputData(inputVars())
      handler.setVue(this)
      handler.execute(repository, [id, data])
    },
    async store () {
      const confirm = await this.$confirm('are you sure you want to add this quantity?')
      if (!confirm) return
      const handler = rackEggHandler
      const repository = rackEggRepository.store
      const { id, ...data } = this.$getInputData(inputVars())
      handler.setVue(this)
      handler.execute(repository, [data])
        .then(() => {
          this.incubatorRackId = null
        })
    },
    rackNumberClass (item) {
      const classes = []
      const textColor = () => {
        const loadedEggs = item.loaded_eggs
        if (!loadedEggs) return ['green--text']
        const date = Window.getCurrentDate(new Date(loadedEggs.updated_at))
        const currentDate = Window.getCurrentDate()
        if (date === currentDate) return ['red--text']
        return ['blue-grey--text', 'text--darken-3']
      }
      return classes.concat(textColor())
    },
  },
}
</script>